/* eslint-disable camelcase */

import { Model } from '../../types'
import schema from '../../../schemas/Farm.json'

import Geolocation from './Geolocation'
import DetailsUser from './DetailsUser'
import User from './User'
import Asset from './Asset'
import FarmProperty from './FarmProperty'
import DrylandCrop from './DrylandCrop'
import IrrigatedCrop from './IrrigatedCrop'
import ExtensiveLivestock from './ExtensiveLivestock'
import RainGauge from './RainGauge'
import Address from './Address'
import Paddock from './Paddock'
import LivestockAgistmentCollection from './LivestockAgistmentCollection'
import LivestockFeedCollection from './LivestockFeedCollection'
import LivestockAgistedAwayCollection from './LivestockAgistedAwayCollection'
import LivestockBreedingDetailsCollection from './LivestockBreedingDetailsCollection'
import LivestockInventoryCollection from './LivestockInventoryCollection'
import LivestockPurchasesCollection from './LivestockPurchasesCollection'
import LivestockSalesCollection from './LivestockSalesCollection'
import LivestockWoolCollection from './LivestockWoolCollection'
import ExistingPlantItem from './ExistingPlantItem'
import NewPlantPurchase from './NewPlantPurchase'
import Project from './Project'
import SoldPlantSale from './SoldPlantSale'
import File from './File'
import BusinessFarm from './BusinessFarm'
import Business from './Business'
import FarmPeriod from './FarmPeriod'
import Period from './Period'
import CropTypeFarmPeriod from './CropTypeFarmPeriod'
import CropType from './CropType'
import ProfitFocusGroup from './ProfitFocusGroup'
import FarmLivestockEnterprise from './FarmLivestockEnterprise'

export default class Farm extends Model {
  static entity = 'farms'

  static primaryKey = 'id'

  entity = 'farms'

  static fields () {
    return {
      id: this.number(null),
      farm_name: this.string(null),
      billing_notes: this.attr(null),
      manager_id: this.number(null),
      profit_focus_group_id: this.number(null),
      pivot: this.attr(null),
      address_id: this.number(null),
      tagsString: this.string(null),
      geolocation_id: this.attr(null),

      geolocation: this.belongsTo(Geolocation, 'geolocation_id'),
      users: this.belongsToMany(User, DetailsUser, 'details_id', 'user_id'),
      assets: this.hasMany(Asset, 'farm_id', 'id'),
      farm_properties: this.hasMany(FarmProperty, 'farm_id', 'id'),
      dryland_crops: this.hasMany(DrylandCrop, 'farm_id', 'id'),
      irrigated_crops: this.hasMany(IrrigatedCrop, 'farm_id', 'id'),
      extensive_livestock: this.hasMany(ExtensiveLivestock, 'farm_id', 'id'),
      manager: this.belongsTo(User, 'manager_id'),
      rain_gauges: this.hasMany(RainGauge, 'farm_id', 'id'),
      addresses: this.morphMany(Address, 'addressable_id', 'addressable_type'),
      paddocks: this.hasMany(Paddock, 'farm_id'),
      livestock_agistment_collections: this.hasMany(LivestockAgistmentCollection, 'farm_id'),
      livestock_feed_collections: this.hasMany(LivestockFeedCollection, 'farm_id'),
      livestock_agisted_away_collections: this.hasMany(LivestockAgistedAwayCollection, 'farm_id'),
      livestock_breeding_details_collections: this.hasMany(LivestockBreedingDetailsCollection, 'farm_id'),
      livestock_inventory_collections: this.hasMany(LivestockInventoryCollection, 'farm_id'),
      livestock_purchases_collections: this.hasMany(LivestockPurchasesCollection, 'farm_id'),
      livestock_sales_collections: this.hasMany(LivestockSalesCollection, 'farm_id'),
      livestock_wool_collections: this.hasMany(LivestockWoolCollection, 'farm_id'),
      existing_plant_items: this.hasMany(ExistingPlantItem, 'farm_id'),
      new_plant_purchases: this.hasMany(NewPlantPurchase, 'farm_id'),
      projects: this.hasMany(Project, 'details_id'),
      sold_plant_sales: this.hasMany(SoldPlantSale, 'farm_id'),
      files: this.morphMany(File, 'fileable_id', 'fileable_type'),
      businesses: this.belongsToMany(Business, BusinessFarm, 'farm_id', 'business_id'),
      periods: this.belongsToMany(Period, FarmPeriod, 'farm_id', 'period_id'),
      crop_types: this.belongsToMany(CropType, CropTypeFarmPeriod, 'farm_id', 'crop_type_id'),
      crop_type_farm_periods: this.hasMany(CropTypeFarmPeriod, 'farm_id'),
      profit_focus_group: this.belongsTo(ProfitFocusGroup, 'profit_focus_group_id'),
      farm_livestock_enterprises: this.hasMany(FarmLivestockEnterprise, 'farm_id'),
      gps_locations: this.morphMany(Geolocation, 'geolocatable_id', 'geolocatable_type'),
    }
  }

  declare id: number
  declare farm_name: string
  declare billing_notes: string
  declare manager_id: number
  declare profit_focus_group_id: number
  declare pivot: string
  declare address_id: number
  declare tagsString: string
  declare geolocation_id: string

  declare geolocation: Geolocation
  declare users: User[]
  declare assets: Asset[]
  declare farm_properties: FarmProperty[]
  declare dryland_crops: DrylandCrop[]
  declare irrigated_crops: IrrigatedCrop[]
  declare extensive_livestock: ExtensiveLivestock[]
  declare manager: User
  declare rain_gauges: RainGauge[]
  declare addresses: Address[]
  declare paddocks: Paddock[]
  declare livestock_agistment_collections: LivestockAgistmentCollection[]
  declare livestock_feed_collections: LivestockFeedCollection[]
  declare livestock_agisted_away_collections: LivestockAgistedAwayCollection[]
  declare livestock_breeding_details_collections: LivestockBreedingDetailsCollection[]
  declare livestock_inventory_collections: LivestockInventoryCollection[]
  declare livestock_purchases_collections: LivestockPurchasesCollection[]
  declare livestock_sales_collections: LivestockSalesCollection[]
  declare livestock_wool_collections: LivestockWoolCollection[]
  declare existing_plant_items: ExistingPlantItem[]
  declare new_plant_purchases: NewPlantPurchase[]
  declare projects: Project[]
  declare sold_plant_sales: SoldPlantSale[]
  declare files: File[]
  declare businesses: Business[]
  declare periods: Period[]
  declare crop_types: CropType[]
  declare crop_type_farm_periods: CropTypeFarmPeriod[]
  declare profit_focus_group: ProfitFocusGroup
  declare farm_livestock_enterprises: FarmLivestockEnterprise[]
  declare gps_locations: Geolocation[]
}
