import { Model } from '@tailflow/laravel-orion/lib/model'
import type { DefaultPersistedAttributes } from '@tailflow/laravel-orion/lib/types/defaultPersistedAttributes'

export default class Address extends Model<{
  id: number
  created_at: string
  updated_at: string
  type: string
  number: string
  addressable_type: string
  addressable_id: number
  is_primary: string
  address_1: string
  address_2: string
  city: string
  state: string
  postcode: string
  country: string
  description: string
},
DefaultPersistedAttributes > {
  public $resource (): string {
    return 'addresses'
  }
}
