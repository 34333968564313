/* eslint-disable camelcase */

import { Model } from '../../types'

export default class Geolocation extends Model {
  static entity = 'geolocations'

  static primaryKey = 'id'

  entity = 'geolocations'

  static fields () {
    return {
      id: this.number(null),
      name: this.string(null),
      location: this.attr(null),
      geolocatable_id: this.number(null),
      geolocatable_type: this.string(null),
    }
  }

  declare id: number
  declare name: string
  declare location: string
  declare geolocatable_id: number
  declare geolocatable_type: string
}
